require('./admin/bootstrap.js');
require('bootstrap-datepicker');
require('jquery-validation');
require('jquery-mask-plugin');
require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
require('dragscroll');

$(function () {
	/*
  	* Auto height no textarea
  	*/
		$('textarea[rows=1]').each(function () {
			this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y:hidden;');
		}).on('input', function () {
			this.style.height = 'auto';
			this.style.height = (this.scrollHeight) + 'px';
		});

	/*
  	* Block selector fullscreen
  	*/
		$('#bsel').on('show.bs.dropdown', function () {
		  	$('body').addClass('modal-open');
		});
		$('#bsel').on('hide.bs.dropdown', function () {
		  	$('body').removeClass('modal-open');
		});
	
	/*
  	* Fancybox
  	*/
	  	$().fancybox({
			selector : '[data-fancybox]',
			margin : [80, 0],
			protect: true,
			arrows: false,
			hash : false,
			buttons : ['close'],
		});

	/*
	 * Datepicker
	*/
		$.fn.datepicker.dates['en'] = {
		    days: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
		    daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
		    daysMin: ["D", "S", "T", "Q", "Q", "S", "S"],
		    months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
		    monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
		    today: "Hoje",
		    clear: "Limpar",
		    format: "dd/mm/yyyy",
		    titleFormat: "MM - yyyy",
		    weekStart: 0
		};
		$('[data-type=date]').datepicker({
			todayHighlight: true,
		    autoclose: true,
		    todayBtn: true,
		});

	/*
  	* Form validation/mask
  	*/
		$('form[data-validate=true]').validate({
			errorPlacement: function(error, element) {},
			highlight: function(element) {
		        $(element).closest('.form-group').addClass('has-error');
		    },
		    unhighlight: function(element) {
		        $(element).closest('.form-group').removeClass('has-error');
		    }
		});

		$(document).ready(function(){
			$('[data-type="cpf"]').mask('ZZZ.ZZZ.ZZZ-ZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } });
			$('[data-type="cnpj"]').mask('ZZ.ZZZ.ZZZ/ZZZZ-ZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } });
			$('[data-type="phone"]').mask('(ZZ) ZZZZ-ZZZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } });
			$('[data-type="cell"]').mask('(ZZ) Z ZZZZ-ZZZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } });
			$('[data-type="date"]').mask('ZZ/ZZ/ZZZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } });
			$('[data-type="money"]').mask("ZZZ.ZZZ.ZZZ.ZZZ,ZZ", { reverse: true, translation: { 'Z': { pattern: /[0-9]/, optional: false } } });
		});	

	/*
  	* Navbar
  	**/
		$('#header').on('show.bs.dropdown', function(){
		    $('body').addClass('modal-open');
		});
		$('#header').on('hide.bs.dropdown', function(){
		    return false;
		});
		$('.navbar-dismiss').on('click', function(){
			$('#header').removeClass('open');
			$('body').removeClass('modal-open');
		});

		$( '.sublink-active' ).each( function () {
			let trigger = $( this ).parent().parent().parent().find( '[data-toggle="collapse"]' );
	    	trigger.click();
	  	});

	/*
  	* Tooltip
  	**/
		$('[data-toggle="tooltip"]').tooltip({
			container: 'body',
			trigger: 'hover',
		});

	/*
	* Special buttons
	**/
		$('#ordenar').on('hide.bs.modal', function (e) {
		  	setTimeout( function () {
		    	window.location.reload();
		    }, 100 );
		});

	/*
	* Change item status
	**/
	$( '.change-status' ).click( function () {
		let item_id = this.value;
		$.ajax({
			type: 'GET'
			, url: `${window.Route}/${item_id}/status`
		});
	});

	/*
	* Change item highlight
	**/
	$( '.change-status-highlight' ).click( function () {
		let item_id = this.value;
		$.ajax({
			type: 'GET'
			, url: `${window.Route}/${item_id}/highlight`
		});
	});

	/*
	* File name (input[type="file"])
	**/
		$( 'input[type="file"]' ).change( function () {
			let label_name = $( this ).parent().find( '.file-name' );
			if ( !this.files.length ) {
				label_name.text( 'Nenhum arquivo selecionado.' );
			} else if ( this.files.length === 1 ) {
				label_name.text( `Arquivo selecionado: ${this.files[ 0 ].name}` );
			} else {
				label_name.text( `${this.files.length} arquivos selecionados.` );
			}
		});

	/*
	* Contador de caracteres
	**/
		var Counter = function ( maxlength ) {
			var counter_badge = document.createElement( 'span' );
			counter_badge.className = 'badge circle form-counter-label';

			counter_badge.set_counter = function ( chars ) {
				var message = '';
				if ( chars > 1 ) {
					message = `${chars} caracteres restantes.`;
				} else if ( chars === 0 ) {
					message = `Limite atingido.`;
				} else {
					message = `1 caractere restante.`;
				}
				counter_badge.innerText = message;
			};

			return counter_badge;
		};

	/*
	* Chama os inputs com contador
	**/
		$('.form-counter').each(function() {
			var maxlength = this.getAttribute( 'maxlength' ) * 1;
			if ( !maxlength ) {
				return console.error( 'Defina um número máximo de caracteres para o contador.' );
			}
			var counter = new Counter( maxlength );
			counter.set_counter( maxlength - this.value.length );

			this.parentNode.appendChild( counter );

			$(this)
				.keyup( function () {
					counter.set_counter( maxlength - this.value.length );
				})
				.focusout( function () {
					counter.set_counter( maxlength - this.value.length );
				});
		});

	/*
	* Sortable
	**/
		const setOrder = function () {
			let message = $( this ).parent().find( '.message-order' );
			setTimeout( () => {
				let childs = this.children;
				let ids = [];

				let url = $(this).attr('data-url');

				if(!url) {
					url = `${window.Route}/reorder`;
				}

				for ( let i = 0, l = childs.length; i < l; i++ ) {
					childs[ i ].dataset.id && ids.push( childs[ i ].dataset.id );
				}

				$.ajax({
					url: url
					, type: 'GET'
					, data: { order: ids.join( ';' ) }
					, success: function ( data ) {
						if ( data && data.success ) { message.removeClass( 'hidden' ); }
					}
				});
			}, 200 );
		};

		$( '.sortable-photo' )
			.sortable({
				handle: 'img'
			})
			.on( 'sortable:deactivate', function ( e, ui ) {
				setOrder.apply( this );
	  	});

		$( '.sortable-text' )
			.sortable()
			.on( 'sortable:deactivate', function ( e, ui ) {
				setOrder.apply( this );
	  	});

	/*
	* Checkboxes (select all)
	* ".checkboxes-control" is the table
	**/
  		/*$( '.checkboxes-control' ).each( function () {
    		let head_checkbox = $( this ).find( 'thead' ).find( 'input[type="checkbox"]' );
    			if ( !head_checkbox.data( 'checkboxes' ) ) {
      				return console.log( 'O Checkbox do THead não possui a propriedade "data-checkboxes".' );
    			}
    		
    		let body_checkboxes = $( this ).find( `input[name="${head_checkbox.data( 'checkboxes' )}"]` );

		    let changeAll = function () {
		    	body_checkboxes.attr( 'checked', this.checked );
		    };

    		changeAll.apply( head_checkbox[ 0 ] );
    		head_checkbox.change( function () { changeAll.apply( this ) } );

  		});*/

});